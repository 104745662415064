import React, { useCallback, useMemo } from "react";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { CellProps } from "./Cell";
import { Typography } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Link;
const LinkCell = ({
  column,
  row,
  className,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform, linkAction } = column;
  const { href, text } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("LinkCell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (linkAction) {
        event.preventDefault();
        linkAction(row.original[accessorKey]);
      }
    },
    [accessorKey, linkAction, row.original],
  );

  return (
    <div className={className} style={style}>
      <Typography.Span
        as="a"
        style={{
          display: "block",
          alignContent: "center",
          color: style.color,
          ...textStyle,
        }}
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
        title={text}
      >
        {text}
      </Typography.Span>
    </div>
  );
};

export default LinkCell;
